<template>
    <div>
        <div class="card p-2">
            <TitleButton
                :showBtn="true"
                title="Money Receipt Edit"
                :showAddNew="false"
                @onClickCloseButton="navigateToListPage"
            />

            <div class="row gy-1 pt-75 bg-blue-light rounded-8 mt-1 pb-2">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Party</label>
                    <AsyncSelect
                        placeholder="Party Name, ID, Mobile No"
                        v-model="selectedContact"
                        :api-service="fetchContactProfiles"
                        :format-label="formatPatientLabel"
                        :option-selected="onchangeContact"
                        :additional-option="additionalOption"
                        :additional-query="{ with_mobile: 1 }"
                        label="name"
                        :reduce="name => name.id"
                        disabled
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="salesFormData.date"
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Received Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="salesFormData.receipt_date"
                        readonly
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Voucher No</label>
                    <div class="input-group input-group-merge invoice-edit-input-group">
                        <div class="input-group-text bg-light">
                            <span>{{ prefix }}-</span>
                        </div>
                        <input
                            type="number"
                            min="1"
                            class="form-control invoice-edit-input"
                            placeholder=""
                            v-model="serial"
                            readonly
                        />
                    </div>
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Received In</label>
                    <v-select
                        v-model="salesFormData.account_head_id"
                        placeholder="Select Cash & Bank Account"
                        class="w-100"
                        :options="accountHeads"
                        label="name"
                        :reduce="(name) => name.id"
                        disabled
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Received Ref</label>
                    <input
                        type="hidden"
                        class="form-control invoice-edit-input date-picker flatpickr-input"
                        readonly="readonly"
                    />
                    <div class="input-group input-group-merge invoice-edit-input-group">
                        <div class="input-group-text">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-hash"
                            >
                                <line x1="4" y1="9" x2="20" y2="9"></line>
                                <line x1="4" y1="15" x2="20" y2="15"></line>
                                <line x1="10" y1="3" x2="8" y2="21"></line>
                                <line x1="16" y1="3" x2="14" y2="21"></line>
                            </svg>
                        </div>
                        <input
                            v-model="salesFormData.receipt_reference"
                            type="text"
                            class="form-control invoice-edit-input"
                            placeholder=""
                            readonly
                        />
                    </div>
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">R.Ref Date</label>
                    <input
                        class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1"
                        type="date"
                        placeholder=""
                        v-model="salesFormData.receipt_reference_date"
                        readonly
                    />
                </div>

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Balance</label>
                    <input
                        type="hidden"
                        class="form-control invoice-edit-input date-picker flatpickr-input"
                        readonly
                    />
                    <div class="input-group input-group-merge invoice-edit-input-group">
                        <input
                            v-model="balance"
                            readonly
                            type="text"
                            class="form-control invoice-edit-input"
                            placeholder=""
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Admission info</label>
                    <v-select
                        v-model="salesFormData.ipd_register_id"
                        placeholder="Select Admission Info"
                        class="w-100"
                        :options="ipdRegisters"
                        label="text"
                        :reduce="text => text.id"
                        disabled
                    />
                </div>
                <div v-if="salesFormData.receipt_type === 'advance'" class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Advance Amount</label>
                    <div class="input-group">
                        <input
                            v-model="salesFormData.total_paid_amount"
                            type="number"
                            class="form-control"
                            placeholder=""
                            readonly
                        />
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">VDS Account</label>
                    <v-select
                        v-model="salesFormData.vat_challan_account_head_id"
                        placeholder="Select VAT Challan Receivable"
                        class="w-100"
                        :options="vatChallanHeads"
                        label="name"
                        :reduce="(name) => name.id"
                        disabled
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">AIT Accounts</label>
                    <v-select
                        v-model="salesFormData.ait_challan_account_head_id"
                        placeholder="Select AIT Challan Receivable"
                        class="w-100"
                        :options="aitChallanHeads"
                        label="name"
                        :reduce="(name) => name.id"
                        disabled
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Discount Account</label>
                    <v-select
                        v-model="salesFormData.discount_account_head_id"
                        placeholder="Select Discount Account"
                        class="w-100"
                        :options="discountAccountHeads"
                        label="name"
                        :reduce="(name) => name.id"
                        disabled
                    />
                </div>
            </div>

            <div class="mt-2"></div>
            <BillPayTable v-if="salesFormData.receipt_type === 'invoice'" :payBills="invoiceDueBills" :isDisabled="true" />

            <div class="row mt-1">
                <div class="col-12">
                    <div class="mb-2">
                        <label for="note" class="form-label fw-bold">Memo</label>
                        <textarea
                            v-model="salesFormData.description"
                            placeholder="Memo"
                            class="form-control"
                            rows="2"
                            id="note"
                            readonly
                        ></textarea>
                    </div>
                </div>
            </div>

            <div class="d-flex gap-1">
                <button
                    :disabled="saveButtonLoader"
                    @click="handleReturn"
                    class="btn btn-primary"
                >
                    Return
                </button>
                <button
                    @click="navigateToListPage"
                    class="btn btn-outline-secondary cancel-btn"
                >
                    Cancel
                </button>
            </div>
        </div>
        <GlobalLoader />
    </div>
</template>

<script setup>
import BillPayTable from "@/components/molecule/company/inventory/sales/BillPayTable.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import TitleButton from "@/components/atom/TitleButton.vue";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useRoute, useRouter} from "vue-router";
import {useQuery} from "@/services/utils/query";
import {computed, inject, onMounted, ref} from "vue";
import {editVoucherNumber} from "@/services/utils/voucherNumberGenerator";
import useBillPayData from "@/data/inventory/billPay";
import handlePurchaseAndSales from "@/services/modules/purchase";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import handlePurchase from "@/services/modules/purchase";
import handleContact from "@/services/modules/contact";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import useDate from "@/services/utils/day";

const router = useRouter();
const route = useRoute();
const date = useDate();
const companyId = computed(() => route.params.companyId);
const voucherQuery = `?company_id=${route.params.companyId}&voucher_type=payment_voucher&txn_type=bill_payment`;

const {
    getInvoiceDueList,
    getAccountHead,
    accountHeads,
    balance,
    business,
    prefix,
    serial,
    invoiceDueBills,
    params,
    payment
} = useBillPayData(voucherQuery);

const loader =ref(false)
const saveButtonLoader = ref(false);
const showError = inject("showError");
const showSuccess = inject("showSuccess");
const ipdRegisters = ref([]);
const accountReceivables = ref([]);
const vatChallanHeads = ref([]);
const aitChallanHeads = ref([]);
const discountAccountHeads = ref([]);
const selectedContact = ref(null);
const additionalOption = ref([]);
const { salesFormData, fetchMoneyReceipt, returnMoneyReceipt } = handlePurchaseAndSales();
const {fetchAccountReceivable, getAccountHeadBySlag} = handlePurchase()
const { fetchIpdRegisterList } = handleHospitalBilling()
const {fetchContactProfiles} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper()

const navigateToListPage = () =>  {
    router.push({ name: "money-receipt-list", params, query: route.query });
}

const getAccountReceivable = () => {
    fetchAccountReceivable(`?company_id=${companyId.value}`).then(({status, data}) => {
        if (status) accountReceivables.value = data
        if (data.length < 1) {
            return salesFormData.value.account_receivable_id = null;
        }

        salesFormData.value.account_receivable_id = data[0].id
    })
}

const onchangeContact = (e) => {
    getInvoiceDueList(e.id);
    getIPDRegisterList(e.id);
}

const getIPDRegisterList = (id) => {
    salesFormData.value.ipd_register_id = ''
    const companyQuery = `?company_id=${companyId.value}`;
    fetchIpdRegisterList(`${companyQuery}&contact_profile_id=${id}`).then(res => {
        if (!res.status) {
            return ipdRegisters.value = [];
        }
        ipdRegisters.value = res.data;
    })
}

const handleReturn = () => {
    saveButtonLoader.value = true;
    salesFormData.value.contact_profile_id = selectedContact.value;
    salesFormData.value.voucher_no = prefix.value + "-" + serial.value;
    salesFormData.value.transactions = JSON.stringify(invoiceDueBills.value);
    salesFormData.value.mop_account_head_id = salesFormData.value.account_head_id;
    salesFormData.value.receipt_type = 'invoice_return';
    returnMoneyReceipt(route.params.receiptId, salesFormData.value)
        .then((res) => {
            saveButtonLoader.value = false;
            if (res.status) {
                navigateToListPage();
            }
            if (!res.status) {
                showError(res.message);
            }
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() => {
            saveButtonLoader.value = false;
        });
}

onMounted(async () => {
    const companyQuery = `?company_id=${companyId.value}`;
    getAccountHead();
    await fetchMoneyReceipt(route.params.receiptId, companyQuery)
        .then(res => {
            if(res.status) {
                getIPDRegisterList(res.data.contact_profile_id);
                salesFormData.value = res.data;
                salesFormData.value.receipt_date = res.data.mr_receipt_date
                salesFormData.value.receipt_reference_date = res.data.mr_receipt_reference_date
                invoiceDueBills.value = salesFormData.value.general;
                let voucher = editVoucherNumber(salesFormData.value.voucher_no);
                prefix.value = voucher.prefix;
                serial.value = voucher.serial;
                additionalOption.value = [{
                    id: res.data.contact.id,
                    name: res.data.contact.name
                }];
            }
        })
    selectedContact.value = salesFormData.value.contact_profile_id;
    getAccountReceivable();

    const vatChallanHeadsRes = getAccountHeadBySlag("vat_challan", companyQuery)
    const aitChallanHeadsRes = getAccountHeadBySlag("ait_challan", companyQuery)
    const discountAccountHeadsRes = getAccountHeadBySlag("discount_account", companyQuery)
    Promise.all([
        vatChallanHeadsRes.then(({data}) => {
            if (data) vatChallanHeads.value = data
        }),
        aitChallanHeadsRes.then(({data}) => {
            if (data) aitChallanHeads.value = data
        }),
        discountAccountHeadsRes.then(({data}) => {
            if (data) discountAccountHeads.value = data
        })
    ]).then(() => {
        salesFormData.value.date = date.currentDate();
        loader.value = false
    }).catch(() => {
        loader.value = false
    })
});

</script>